<template>
  <div ref="chartdiv" class="chart"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import chartLocaleMixin from "../../../mixins/chart-locale-mixin";

export default {
  name: "TotalOrdersChart",
  mixins: [chartLocaleMixin],
  props: {
    values: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      /** @type {am5.Root} */
      root: null,
      /** @type {am5xy.ColumnSeries} */
      series: null,
    };
  },
  watch: {
    currentLocale(old, value) {
      if (old !== value) {
        this.root.dispose();
        this.draw();
      }
    },
    values() {
      this.setData();
    },
  },
  methods: {
    setData() {
      this.series.data.setAll(this.values);
    },
    draw() {
      this.root = am5.Root.new(this.$refs.chartdiv);
      this.root._logo.dispose();

      this.root.locale = this.getLocale();

      const myTheme = am5.Theme.new(this.root);

      myTheme.rule("AxisLabel", ["minor"]).setAll({
        dy: 1,
      });

      this.root.setThemes([
        am5themes_Animated.new(this.root),
        myTheme,
        am5themes_Responsive.new(this.root),
      ]);

      const chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panX: false,
          panY: false,
          wheelX: "none",
          wheelY: "none",
          paddingLeft: 25,
        })
      );

      const cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(this.root, {
          behavior: "zoomX",
        })
      );
      cursor.lineY.set("visible", false);

      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(this.root, {
          maxDeviation: 0,
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(this.root, {
            strokeWidth: 0,
            minorGridEnabled: true,
            minorLabelsEnabled: true,
          }),
          tooltip: am5.Tooltip.new(this.root, {}),
        })
      );

      xAxis.set("dateFormats", {
        day: "dd",
      });

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          renderer: am5xy.AxisRendererY.new(this.root, {
            strokeWidth: 0,
            forceHidden: true,
          }),
        })
      );

      const yRenderer = yAxis.get("renderer");
      yRenderer.grid.template.set("forceHidden", true);
      yRenderer.labels.template.set("forceHidden", true);

      const xRenderer = xAxis.get("renderer");
      xRenderer.grid.template.set("forceHidden", true);

      this.series = chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          tooltip: am5.Tooltip.new(this.root, {
            labelText: "{valueY}",
          }),
        })
      );

      this.series.columns.template.setAll({
        strokeOpacity: 0,
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
        width: 9,
      });

      this.series.columns.template.set("interactive", true);
      this.series.set("fill", am5.color(0xc8c8dc));
      this.series.columns.template.states.create("hover", {
        fill: am5.color(0x001991),
        stroke: am5.color(0x001991),
      });

      this.series.bullets.push(() => {
        return am5.Bullet.new(this.root, {
          locationX: 0.5,
          locationY: 1,
          sprite: am5.Label.new(this.root, {
            centerY: am5.percent(100),
            centerX: am5.percent(50),
            text: "{valueY}",
            populateText: true,
          }),
        });
      });

      this.series.data.processor = am5.DataProcessor.new(this.root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["date"],
      });

      this.setData();
      this.series.appear(1000);
      chart.appear(1000, 100);
    },
  },
  mounted() {
    this.draw();
  },
  beforeDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
}
</style>
