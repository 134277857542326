import am5locales_en_US from "@amcharts/amcharts5/locales/en_US";
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_BR";
import { LOCALES } from "@/constants";

export default {
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    getLocale() {
      if (this.currentLocale === LOCALES.PT_BR) {
        return am5locales_pt_BR;
      }

      return am5locales_en_US;
    },
  },
};
